import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, Observable, take } from 'rxjs';
import { AppInterface } from '../../interfaces/app.interface';
import { environment } from '../../../../environments/environment';
import { DisplayLoadService } from '../display-load-service/display-load.service';
import { CollectionApiService } from '../collection-service/collection-api.service';
import { CollectionManagerItemDTO } from '../../interfaces/collection.interface';

@Injectable({
  providedIn: 'root',
})
export class ManagerService {
  private managerEndpoint = '/appManager';
  private appPendingApprovalsEndpoint = '/appManager/pendingApprovals';
  private collectionPendingApprovalsEndpoint = '/collectionManager/pendingApprovals';

  constructor(
    private http: HttpClient,
    private displayLoadService: DisplayLoadService,
    private collectionApiService: CollectionApiService
  ) { }

  public getApps(): Observable<AppInterface[]> {
    this.displayLoadService.loadingStart();
    return this.http
      .get<AppInterface[]>(`${environment.baseUrl}${this.managerEndpoint}`)
      .pipe(
        take(1),
        finalize(() => {
          this.displayLoadService.loadingDone();
        }),
      );
  }

  public getAppsPendingApprovals(): Observable<AppInterface[]> {
    return this.http
      .get<
        AppInterface[]
      >(`${environment.baseUrl}${this.appPendingApprovalsEndpoint}`)
      .pipe(take(1));
  }

  public getCollectionsPendingApprovals(): Observable<CollectionManagerItemDTO[]> {
    return this.http
      .get<
      CollectionManagerItemDTO[]
      >(`${environment.baseUrl}${this.collectionPendingApprovalsEndpoint}`)
      .pipe(take(1));
  }

  public getCollections(): Observable<CollectionManagerItemDTO[]> {
    return this.collectionApiService.listManager();
  }

}
