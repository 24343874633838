<div class="start-page-result-container">
  <span class="start-page-result-container-title-page">Start Page</span>

  <ul>
    @for (app of filteredApps().startPage; track app.appId) {
      <li class="application-container">
        <app
          (click)="logAppSearchResult(app)"
          [clickOnAppBehavior]="ClickOnAppBehavior.OPEN_APP_BY_URL"
          [app]="app"
          [showAddRemoveButton]="false"
        ></app>
      </li>
    }
  </ul>

  <ul>
    @for (app of filteredApps().bookmark; track app.appId) {
      <li class="application-container">
        <bookmark (click)="logBookmarkResult(app)" [bookmark]="app"></bookmark>
      </li>
    }
  </ul>

  <no-apps-found [showMessage]="!filteredApps().startPage.length && !filteredApps().bookmark.length"></no-apps-found>
</div>
