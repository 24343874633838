import { Injectable } from '@angular/core';
import { NavigationItem } from '../../interfaces/navigation-items';
import { NavigationSetup } from './navigation-setup';
import { WelcomeWizardSeenService } from '../welcome-wizard/welcome-wizard-seen.service';
import { Router } from '@angular/router';
import { ResetStartpagePopupComponent } from '../../components/reset-startpage-popup/reset-startpage-popup.component';
import { PermissionsService } from '../../../pages/app-integrator-page/services/permissions.service';
import { UserService } from '../user-service/user.service';

/**
 * When adding a new route to the application, add a new NavigationItem to the NavigationItems-Array.
 * This ensures the header-component will be able to display the name of the current route.
 */
@Injectable({
  providedIn: 'root',
})
export class NavigationItemsService {
  private helpPage: NavigationItem | undefined;
  private profilePage: NavigationItem | undefined;

  constructor(
    private welcomeWizardSeenService: WelcomeWizardSeenService,
    private router: Router,
    private permissionsService: PermissionsService,
    protected userService: UserService,
  ) { }

  public getStartPage(): NavigationItem {
    return NavigationSetup.START_PAGE;
  }

  public getAppFinder(): NavigationItem {
    return NavigationSetup.APP_FINDER;
  }

  public getAppDetailPage(): NavigationItem {
    return NavigationSetup.APP_DETAIL_PAGE;
  }

  public getAppIntegratorPage(): NavigationItem {
    return NavigationSetup.APP_INTEGRATOR_PAGE;
  }

  public getEditApp(): NavigationItem {
    return NavigationSetup.EDIT_APP;
  }

  public getCollectionIntegratorPage(): NavigationItem {
    return NavigationSetup.COLLECTION_INTEGRATOR_PAGE;
  }

  public getEditCollection(): NavigationItem {
    return NavigationSetup.EDIT_COLLECTION;
  }

  public getCreateCollection(): NavigationItem {
    return NavigationSetup.CREATE_COLLECTION;
  }

  public getLaunchpadImportPage(): NavigationItem {
    return NavigationSetup.LAUNCHPAD_IMPORT_PAGE;
  }

  public getAppManager(): NavigationItem {
    return NavigationSetup.APP_MANAGER_PAGE;
  }

  public getCollectionDetail(): NavigationItem {
    return NavigationSetup.COLLECTION_DETAIL_PAGE;
  }


  public getWelcomeWizard(): NavigationItem {
    return {
      path: '',
      routerLink: '',
      name: 'Welcome Wizard',
      hideOnMobile: true,
      click: async () => {
        this.welcomeWizardSeenService.showWizard();
        await this.router.navigate([NavigationSetup.START_PAGE.path]);
      },
    };
  }

  public getHelpPage(): NavigationItem {
    if (!this.helpPage) {
      this.helpPage = NavigationSetup.HELP_PAGE;
      this.helpPage.children = [
        NavigationSetup.DOCUMENTATION,
        NavigationSetup.SUPPORT,
        this.getWelcomeWizard(),
      ];
    }
    return this.helpPage;
  }

  public getResetStartPage(): NavigationItem {
    const resetStartPage: NavigationItem = NavigationSetup.RESET_START_PAGE;
    resetStartPage.click = () => {
      ResetStartpagePopupComponent.instance.openDialog();
    };
    return resetStartPage;
  }

  public getProfilePage(): NavigationItem {
    if (!this.profilePage) {
      this.profilePage = NavigationSetup.PROFILE_PAGE;
      const children: NavigationItem[] = [this.getResetStartPage()];
      const user = this.userService.getUser();

      if (this.permissionsService.canUseAdminUI(user)) {
        children.push(NavigationSetup.ADMIN_UI_PAGE);
      }

      this.profilePage.children = children;
    }
    return this.profilePage;
  }

  public getNavigationItems(): NavigationItem[] {
    return [
      this.getStartPage(),
      this.getAppFinder(),
      this.getAppDetailPage(),
      NavigationSetup.APP_MANAGER_PAGE,
      NavigationSetup.FEEDBACK_PAGE,
      this.getHelpPage(),
      this.getProfilePage(),
      this.getAppIntegratorPage(),
      NavigationSetup.ADMIN_UI_PAGE,
      NavigationSetup.LAUNCHPAD_IMPORT_PAGE,
      this.getCollectionIntegratorPage(),
      this.getEditCollection(),
      this.getCreateCollection(),
      this.getCollectionDetail()
    ];
  }

  public getSideNavItems(): NavigationItem[] {
    return [
      this.getStartPage(),
      this.getAppFinder(),
      NavigationSetup.APP_MANAGER_PAGE,
      NavigationSetup.FEEDBACK_PAGE,
      this.getHelpPage(),
      /**
       * The Profile-Page also needs to be shown, but we need to show the actual name of the user instead of the
       * navItem.name, therefore we add it manually in the header.component.html
       */
    ];
  }
}
