import {Injectable, } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DisplayLoadService {

  private loadState: boolean = false;

  constructor() {
  }


  public loadingStart(): void {
    this.loadState = true;
  }

  public loadingDone(): void {
    this.loadState = false;
  }

  public isLoading(): boolean {
    return this.loadState;
  }
}
