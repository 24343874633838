@if (app) {
<section
  (click)="onAppClicked()"
  class="app-container"
  [ngClass]="{
    small: displayType === DisplayType.SMALL,
    big: displayType === DisplayType.BIG,
  }"
>
  <div (click)="stopPropagation($event); onAppClicked()" class="left-part">
    <app-icon [app]="app" [displayType]="displayType" />
  </div>

  <div class="right-part">
    <div class="label">
      <div [tooltip]="app.title!" [toolTipAppfinder]="isAppFinderPage">
        <a
          #titleElement
          (click)="stopPropagation($event); onAppClicked()"
          class="title line-clamp-2"
          [ngClass]="{
            '!cursor-default':
              clickOnAppBehavior === ClickOnAppBehavior.NO_BEHAVIOR,
          }"
        >
          {{ app.title }}
        </a>
        <a
          (click)="stopPropagation($event); onAppClicked()"
          class="subtitle line-clamp-1"
          [ngClass]="{
            '!cursor-default':
              clickOnAppBehavior === ClickOnAppBehavior.NO_BEHAVIOR,
          }"
        >
          {{ app.subtitle }}
        </a>
      </div>
    </div>
    @if (showAddRemoveButton) {
      <div class="add-remove-button">
        <add-remove-app-button
          [isFromDetailPage]="isFromDetailPage"
          [app]="app"
          [recommendSectionname]="recommendSectionName"
          (click)="stopPropagation($event)"
        />
      </div>
    }
  </div>
</section>
} @else {
  <div class="app-shadow-container">
    <div class="right-part">
      <div class="icon"></div>
    </div>
    <div class="left-part">
      <div class="title"></div>
      <div class="subtitle"></div>
    </div>
  </div>
}
