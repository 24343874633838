import { Component, computed, Input, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResults } from '../../interfaces/search-results.interface';
import { SearchResultsService } from 'src/app/pages/start-page/services/search-results.service';
import { NoAppsFoundComponent } from "./components/no-apps-found/no-apps-found.component";
import { StartPageResultComponent } from "./components/start-page-result/start-page-result.component";
import { AppsFinderResultComponent } from "./components/apps-finder-result/apps-finder-result.component";
import { CollectionUIService } from "../../../pages/start-page/services/collection-ui.service";
import { AppInterface } from "../../interfaces/app.interface";
import { SearchCriteria } from "../../services/apps-search-service/search-criteria.interface";
import { AppTitleCriteria } from "../../services/apps-search-service/criterias/app-title-criteria";
import { AppNameCriteria } from "../../services/apps-search-service/criterias/app-name-criteria";
import { AppDescriptionCriteria } from "../../services/apps-search-service/criterias/app-description-criteria";
import { AppSubtitleCriteria } from "../../services/apps-search-service/criterias/app-subtitle-criteria";
import { AppKeywordsCriteria } from "../../services/apps-search-service/criterias/app-keywords-criteria";
import { AppsSearchService } from "../../services/apps-search-service/apps-search.service";
import { CollectionManagerItemDTO } from "../../interfaces/collection.interface";
import { ManagerService } from "../../services/manager/manager.service";

@Component({
  selector: 'search-result',
  standalone: true,
  imports: [
    CommonModule,
    StartPageResultComponent,
    AppsFinderResultComponent,
    NoAppsFoundComponent,
  ],
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent {
  @Input() filteredApps: Signal<SearchResults> = signal({
    finder: [],
    startPage: [],
    bookmark: [],
  });
  @Input() openSearchResult: boolean | number = false;
  @Input() searchQuery: string = '';

  protected collections: CollectionManagerItemDTO[] = [];

  private collectionApps: Signal<AppInterface[]> = this.collectionUIService.allAppsOfFollowedCollections;

  private searchCriteria: SearchCriteria[] = [
    new AppTitleCriteria(),
    new AppNameCriteria(),
    new AppDescriptionCriteria(),
    new AppSubtitleCriteria(),
    new AppKeywordsCriteria(),
  ];

  constructor(
    protected searchResultsService: SearchResultsService,
    private collectionUIService: CollectionUIService,
    private appsSearchService: AppsSearchService,
    private managerService: ManagerService
  ) {
    this.getCollections();
  }

  public getCollections() {
    this.managerService.getCollections().subscribe((_collections) => {
      this.collections = _collections;
    });
  }

  private collectionSearchResults: Signal<AppInterface[]> = computed(() => {
    const apps = this.collectionApps() || [];
    const query = this.searchQuery.trim();
    if (!query) {
      return [];
    }
    return this.appsSearchService.search(apps, query, this.searchCriteria);
  });
  protected modifiedFilteredApps: Signal<SearchResults> = computed(() => {
    const originalResults = this.filteredApps();
    const collectionResults = this.collectionSearchResults();

    // Combine collection apps with startPage apps
    const combinedStartPage = [...originalResults.startPage, ...collectionResults];

    // Remove duplicates based on appId
    const uniqueStartPage = Array.from(
      new Map(combinedStartPage.map(app => [app.appId, app])).values()
    );

    return {
      ...originalResults,
      startPage: uniqueStartPage,
    };
  });

  closeSearchResults(): void {
    this.searchResultsService.shouldOpenSearch.next(false);
    this.openSearchResult = false;
  }

  showSearchResult(): boolean {
    if (typeof this.openSearchResult === 'boolean') {
      return this.openSearchResult;
    } else {
      return this.openSearchResult > 2;
    }
  }
}
