<div class="apps-finder-result-container">
  <span class="apps-finder-result-container-title-page">App Finder</span>

  <ul>
    @for (app of filteredApps().finder; track app.appId) {
      <li class="application-container">
        <app
          (click)="logAppSearchResult(app)"
          [app]="app"
          [showAddRemoveButton]="false"
        ></app>
      </li>
    }
  </ul>

  <ul>
    @for (collection of collections; track collection.id) {
      <li class="collection-item">
        <collection-icon class="ml-2" [icons]="collection.icons" [displayType]="displayType.SMALL"></collection-icon>
        <p [tooltip]="collection.title">{{ collection.title }}</p>
      </li>
    }
  </ul>


  <no-apps-found [showMessage]="!filteredApps().finder.length"></no-apps-found>
</div>
