import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppButtonComponent } from '../app-button/app-button.component';
import { PlatformService } from '../../services/platform-service/platform.service';
import { ButtonTypeEnum } from '../../enums/buttonType.enum';
import { ScrollService } from '../../services/scroll-service/scroll.service';
import { DragDropStateService } from '../../services/drag-and-drop-service/drag-and-drop-state.service';

@Component({
  selector: 'dialog-popup',
  standalone: true,
  imports: [CommonModule, AppButtonComponent],
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss'],
})
export class DialogPopupComponent implements AfterViewInit {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) activePrimaryButton: boolean = true;
  @Input({ required: true }) activeSecondaryButton: boolean = true;
  @Input() isInfoDialog: boolean = false;
  @Input() enableScrollWhenOverFlow: boolean = false;
  @Input() primaryButtonDisabled: boolean = false;
  @Input() primaryButtonName: string = '';
  @Input() secondaryButtonName: string = '';
  @Input() isAutoHeight: boolean = false;
  @Input() displayWarningIcon!: boolean;

  @Input() secondSecondaryButtonName: string = '';
  @Input() activeSecondSecondaryButton: boolean = false;
  @Input() secondSecondaryButtonDisabled: boolean = false;

  @ViewChild('dialog') dialogRef?: ElementRef;
  @ViewChild('dialogContent') dialogContentRef?: ElementRef;

  @Output() primaryButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondaryButtonClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() outsideContentClicked: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() dialogEscapeCloseClicked: EventEmitter<void> =
    new EventEmitter<void>();

  @Output() secondSecondaryButtonClicked: EventEmitter<void> =
    new EventEmitter<void>();

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly event = event;

  constructor(
    protected platformService: PlatformService,
    private scrollService: ScrollService,
    private dragDropStateService: DragDropStateService,
  ) {}

  ngAfterViewInit(): void {
    this.dialogRef?.nativeElement.addEventListener('cancel', () => {
      this.dialogEscapeCloseClicked.emit();
    });
  }

  public openDialog() {
    if (!this.scrollService.isFinishScroll()) {
      return;
    }
    this.scrollService.disableScroll();
    this.dragDropStateService.setDialogOpen(true);
    this.dialogRef?.nativeElement.showModal();
  }

  public closeDialog() {
    this.dialogRef?.nativeElement.close();
    this.scrollService.enableScroll();
    this.dragDropStateService.setDialogOpen(false);
  }

  public primaryButtonClickedMethod(event: MouseEvent): void {
    this.stopPropagationAndPreventDefault(event);
    this.closeDialog();
    this.primaryButtonClicked.emit();
  }

  public secondaryButtonClickedMethod(event: MouseEvent): void {
    this.stopPropagationAndPreventDefault(event);
    this.closeDialog();
    this.secondaryButtonClicked.emit();
  }

  public secondSecondaryButtonClickedMethod(event: MouseEvent): void { // NEW
    this.stopPropagationAndPreventDefault(event);
    this.closeDialog();
    this.secondSecondaryButtonClicked.emit();
  }

  stopPropagationAndPreventDefault(event: MouseEvent) {
    this.stopPropagation(event);
    event.preventDefault();
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  public backgroundClick(event: MouseEvent) {
    this.outsideContentClicked.emit();
    if (!this.platformService.isMobile()) {
      this.closeDialog();
      this.stopPropagationAndPreventDefault(event);
    }
  }
}
