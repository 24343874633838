import { Component, Input, Signal } from '@angular/core';
import { ApplicationComponent } from "../../../app/application.component";
import { BookmarkComponent } from "../../../bookmark/bookmark.component";
import { NoAppsFoundComponent } from "../no-apps-found/no-apps-found.component";
import { SearchResults } from "../../../../interfaces/search-results.interface";
import { LogEventService } from "../../../../services/log-event-service/log-event.service";
import { AppInterface, ClickOnAppBehavior } from "../../../../interfaces/app.interface";


@Component({
  selector: 'start-page-result',
  standalone: true,
  imports: [ApplicationComponent, BookmarkComponent, NoAppsFoundComponent],
  templateUrl: './start-page-result.component.html',
  styleUrls: ['./start-page-result.component.scss'],
})
export class StartPageResultComponent {
  @Input() filteredApps!: Signal<SearchResults>;

  constructor(private logEventService: LogEventService) {
  }

  logAppSearchResult(app: AppInterface): void {
    this.logEventService.logServer('app', 'searchResult', app.appId + '');
  }

  logBookmarkResult(app: AppInterface): void {
    this.logEventService.logServer('bookmark', 'searchResult', app.appInstanceId);
  }

  protected readonly ClickOnAppBehavior = ClickOnAppBehavior;
}
