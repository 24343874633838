import { SearchCriteria } from '../search-criteria.interface';
import { CollectionFinderItemDTO } from 'src/app/core/interfaces/collection.interface';

export class CollectionCountriesCriteria implements SearchCriteria {
  getMatchScore(_collection: CollectionFinderItemDTO, _query: string): number {
    if (!_collection.countries) return 0;
    return _collection.countries.some((keyword) => {
      return keyword.toLowerCase().includes(_query);
    })
      ? 1
      : 0;
  }
}
