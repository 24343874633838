import { Injectable } from '@angular/core';
import { ToastMessageService } from '../../../pages/app-finder-page/services/toast-message-service/toast-message.service';
import { isValidUrl } from '../../validators/url.validator';
import { ScrollService } from '../scroll-service/scroll.service';
import { Router } from '@angular/router';
import { NavigationItemsService } from '../navigation-items-service/navigation-items.service';

const HTTPS_PROTOCOL_PREFIX = 'https://';

@Injectable({
  providedIn: 'root',
})

export class UrlService {
  constructor
  (private toastMessageService: ToastMessageService,
    private scrollService: ScrollService,
    private router: Router, private navigationItems: NavigationItemsService) {}

  openURL(
    _link?: string,
    _config: { target?: string; features?: string; isSecure?: boolean } = {},
  ): void {
    if (!_link) {
      // no url -> url invalid -> notify
      this.notifyUrlError();
      return;
    }

    const url = this.getValidatedUrl(_link, _config.isSecure);

    if (url) {
      // valid url -> open it
      window.open(url, _config.target ?? '_blank', _config.features);
    } else {
      // url invalid -> notify
      this.notifyUrlError();
    }
  }

  private getValidatedUrl(link: string, isSecure?: boolean): string | null {

    if (isSecure) {
      // is secure -> just return as-is
      return link;
    }

    let url = link;

    // invalid url -> maybe it helps to add https protocol as a prefix
    if (!isValidUrl(url)) {
      url = HTTPS_PROTOCOL_PREFIX + url;
    }

    return isValidUrl(url) ? url : null;
  }

  private notifyUrlError(): void {
    this.toastMessageService.openToastDialog({
      primaryMessage: 'Could not open URL.',
      secondMessage: 'Target URL is probably incorrect or malicious.',
    });
  }

  openAppDetail(_link?: string) {
    this.scrollService.savePosition(this.router.url);

    if (_link) {
      window.open(_link, '_blank');
    }
  }

}
