<div
  class="search-results-container"
  [ngClass]="showSearchResult() || (searchResultsService.openSearch$ | async) ? 'open' : 'close'"
>
  <ng-container *ngIf="showSearchResult() || (searchResultsService.openSearch$ | async)">
    <start-page-result [filteredApps]="modifiedFilteredApps"></start-page-result>

    <div class="search-result-line"></div>

    <apps-finder-result [filteredApps]="filteredApps" [collections]="collections"></apps-finder-result>

    <div class="close-button-container">
      <div class="close-button" (click)="closeSearchResults()">close</div>
    </div>
  </ng-container>
</div>
