export enum PermissionType {
  AddApp = 'can_add_app',
  ModifyApp = 'can_modify_app',
  UseStartPage = 'can_use_startpage',
  ReadCatalog = 'can_read_catalog',
  ReadFullAppInstances = 'can_read_full_app_instances',
  PublishApp = 'can_publish_app',
  ModifyRoleMapping = 'can_modify_role_mapping',
  UseAdminUI = 'can_use_admin_ui',
  DeleteAppInstances = 'can_delete_app_instances',
  AddBoschManagedApp = 'can_add_bosch_managed_app',
  CanModifyAllowedUrls = 'can_modify_allowed_url',
  CanModifyPrefilling = 'can_modify_prefilling',
  CanModifyAppCreator = 'can_modify_app_creator',
  CanApproveRejectCategoryRequest = 'CAN_APPROVE_REJECT_CATEGORY_REQUEST',
  CanApproveRejectCategoryRequestHr = 'CAN_APPROVE_REJECT_CATEGORY_REQUEST_HR',
  CanApproveRejectCategoryRequestDataManagement = 'CAN_APPROVE_REJECT_CATEGORY_REQUEST_DATAMANAGEMENT',
  // Collection Permissions
  CanAddCollection = 'CAN_ADD_COLLECTION',
  CanModifyCollection = 'CAN_MODIFY_COLLECTION',
  CanPublishCollection = 'CAN_PUBLISH_COLLECTION',
  CanAddBoschManagedCollection = 'CAN_ADD_BOSCH_MANAGED_COLLECTION',
  CanModifyCollectionCreator = 'CAN_MODIFY_COLLECTION_CREATOR'
}
