import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { SearchResultsService } from 'src/app/pages/start-page/services/search-results.service';
import {
  ResetFiltersService
} from "../../../pages/app-finder-page/services/reset-filters-service/reset-filters.service";

@Component({
  selector: 'search-bar',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnDestroy, OnInit {
  @Input() placeholder = 'Search';
  @Input() isCollapsible: boolean = false;
  @Input() isGrayBackground: boolean = true;
  @Input() isLightGrayBackground: boolean = false;
  @Input() noBackgroundWithBorder: boolean = false;
  /**
   * FormControl allows us to use listen to changes on the value of the fields.
   * In this example:
   * query is a FormControl which is used in the html-input and we can subscribe to any change to its value.
   * This is done in the constructor be using the query.valueChanges.subscribe-method.
   */
  @Input() query: FormControl = new FormControl('');
  @Output() queryChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() focusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('inputElement') inputElement: any;

  showInput: boolean = false;
  isAlive$: Subject<boolean> = new Subject();

  constructor(
    protected searchResultsService: SearchResultsService,
    private resetFiltersService: ResetFiltersService
  ) {}

  ngOnInit(): void {
    this.query.valueChanges
      .pipe(takeUntil(this.isAlive$))
      .subscribe((_newValue) => {
        this.queryChanged.emit(_newValue);
        if (_newValue.length < 3) {
          this.searchResultsService.shouldOpenSearch.next(false);
        }
      });

    this.resetFiltersService.resetSearchAction$
      .pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.clearSearchBar();
      })
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.query.setValue('');
  }

  focusout() {
    if (this.isCollapsible) {
      this.showInput = false;
    }
    setTimeout(() => {
      this.searchResultsService.shouldOpenSearch.next(false);
      this.focusChanged.emit(false);

    }, 200);
  }

  focusIn() {
    if (this.query.value.length > 2) {
      this.searchResultsService.shouldOpenSearch.next(true);
    }
    this.focusChanged.emit(true);
  }

  click() {
    if (this.isCollapsible) {
      this.showInput = true;
      this.inputElement.nativeElement.focus();
      this.query.setValue('');
    }
  }

  clearSearchBar() {
    this.query.setValue('');
  }
}
