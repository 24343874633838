import { Component, Input, Signal } from '@angular/core';
import { ApplicationComponent } from "../../../app/application.component";
import { NoAppsFoundComponent } from "../no-apps-found/no-apps-found.component";
import { SearchResults } from "../../../../interfaces/search-results.interface";
import { LogEventService } from "../../../../services/log-event-service/log-event.service";
import { AppInterface, DisplayType } from "../../../../interfaces/app.interface";
import { CollectionManagerItemDTO } from "../../../../interfaces/collection.interface";
import { CollectionIconComponent } from "../../../collection/collection-icon/collection-icon.component";
import { TooltipDirective } from "../../../../directives/tooltip.directive";

@Component({
  selector: 'apps-finder-result',
  standalone: true,
  imports: [ApplicationComponent, NoAppsFoundComponent, CollectionIconComponent, TooltipDirective],
  templateUrl: './apps-finder-result.component.html',
  styleUrls: ['./apps-finder-result.component.scss'],
})
export class AppsFinderResultComponent {
  @Input() filteredApps!: Signal<SearchResults>;
  @Input() collections!: CollectionManagerItemDTO[];

  constructor(private logEventService: LogEventService) {
  }

  logAppSearchResult(app: AppInterface): void {
    this.logEventService.logServer('app', 'searchResult', app.appId + '');
  }

  protected readonly displayType = DisplayType;
}
