import { AppInterface } from '../../../core/interfaces/app.interface';
import { CollectionApiService } from '../../../core/services/collection-service/collection-api.service';
import { computed, Injectable, signal, Signal, WritableSignal } from "@angular/core";
import { WidgetUIService } from './widget-ui.service';
import { CollectionDetailsDTO, CollectionFinderItemDTO } from "../../../core/interfaces/collection.interface";
import { FolderUiService } from "./folder-ui.service";

@Injectable({
  providedIn: 'root',
})
export class CollectionUIService {

  public collectionDetails: WritableSignal<CollectionDetailsDTO[]> = signal([]);
  public collectionFinderList: WritableSignal<CollectionFinderItemDTO[]> = signal([]);

  public currentlySelectedCollection: Signal<CollectionDetailsDTO | undefined> = computed(() => {
    const referenceID = this.foldersUIService.getSelectedFolder()()?.referenceID;
    if (!referenceID) return undefined;
    return this.collectionDetails().find((_collectionDetail) => _collectionDetail.id === referenceID);
  });

  public currentlySelectedCollectionApps: Signal<AppInterface[]> = computed(() => {
    if(!this.currentlySelectedCollection()) return [];
    const collectionAppIDsSet: Set<number> = new Set(this.currentlySelectedCollection()?.apps ?? []);
    return this.widgetUIService.apps().filter(_app => collectionAppIDsSet.has(Number(_app.appId)));
  });

  public allAppsOfFollowedCollections: Signal<AppInterface[]> = computed(() => {
    const appIds: number[] = [];
    for (const collectionDetail of this.collectionDetails()){
      appIds.push(...collectionDetail.apps);
    }
    const collectionAppIDsSet: Set<number> = new Set(appIds);
    return this.widgetUIService.apps().filter(_app => collectionAppIDsSet.has(Number(_app.appId)));
  });

  constructor(
    private widgetUIService: WidgetUIService,
    private collectionApiService: CollectionApiService,
    private foldersUIService: FolderUiService,
  ) {}
}
