export enum ButtonTypeEnum {
  PRIMARY,
  SECONDARY,
  TERTIARY,
}

export enum ButtonSize {
  SMALL = 'small',
  BIG = 'big'
}
